import {get,put,del,post} from '../../utils/request'

/**
 * 分页获取活动列表-app
 */
export function findActivityPageApp(params){
    return get(
        '/activity/findActivityPageApp',
        params
    )
}
/**
 * 分页获取我的活动列表-app
 */
export function findMyActivityPageApp(params){
    return get(
        '/activity/findMyActivityPageApp',
        params
    )
}
/**
 * 获取二维码地址
 */
export function getActivityDetailsUrl(id){
    return get(
        `/activity/getActivityDetailsUrl/2/${id}`
    )
}
/**
 * 获取活动详情-app
 */
export function findActivityApp(id){

    return get(
        `/activity/findActivityApp/${id}`,
    )
}
/**
 * 获取获取活动线索详情-app
 */
export function findActivityClueApp(id){

    return get(
        `/activity/findActivityClueApp//${id}`,
    )
}
/**
 * 编辑活动
 */
export function updateActivity(params){

    return put(
        `/activity/updateActivity`,
        params
    )
}
/**
 * 自增活动数据
 */
export function activityAddNum(id,category){

    return put(
        `/activity/addNum/${id}/${category}`,
    )
}

/**
 * 创建活动表单留资-app
 */
export function createActivityFormMsgApp(params){
    return post(
        '/activity/createActivityFormMsgApp',
        params
    )
}
/**
 * 分享转载活动
 */
export function reprint(params){
    return post(
        '/activity/reprint',
        params
    )
}
/**
 * 获取随机id
 */
export function isBuildSiteBelongUser(id){
    return get(
        `/case/build/isBuildSiteBelongUser/${id}`,
    )
}
/**
 * 创建工地
 */
export function createBuildSiteApp(params){
    return post(
        '/case/build/createBuildSiteApp',
        params
    )
}
/**
 * 删除工地
 */
export function deleteBuildSiteApp(id){
    return del(
        `/case/build/deleteBuildSiteApp/${id}`,
    )
}
/**
 *
 */
export function deleteCardCaseApp(id){
    return del(
        `/case/deleteCardCaseApp/${id}`,
    )
}
/**
 * 获取工地户型
 */
export function findBuildSiteType(){
    return get(
        '/case/build/findBuildSiteType'
    )
}

/**
 * 获取类型
 */
export function findCaseCategory(){
    return get(
        '/case/findCaseCategory'
    )
}
/**
 * 获取随机id
 */
export function isCaseBelongUser(id){
    return get(
        `/case/isCaseBelongUser/${id}`,
    )
}

/**
 * 案例浏览数
 */
export function addNum(id,type){
    return put(
        `/case/build/addNum/${id}/${type}`,
    )
}
/**
 * 创建咨询
 */
export function createCaseConsult(params){
    return post(
        '/article/createConsult',
        params
    )
}
/**
 * 获取二维码地址
 */
export function getBuildSiteDetailsUrl(buildId){
    return get(
        `/case/build/getBuildSiteDetailsUrl/2/${buildId}`
    )
}

