import {get,put,del,post} from '../../utils/request'

/**
 * 获取全部工地
 */
export function findBuildSiteByPageApp(params){
    return get(
        '/case/build/findBuildSiteByPageApp',
        params
    )
}
/**
 * 获取我的工地
 */
export function findMyBuildSiteByPage(params){
    return get(
        '/case/build/findMyBuildSiteByPage',
        params
    )
}

/**
 * 根据id查找工地
 */
export function findBuildSiteByIdApp(id){
    
    return get(
        `/case/build/findBuildSiteByIdApp/${id}`,
    )
}
/**
 * 修改工地
 */
export function updateBuildSiteApp(params){
    return put(
        '/case/build/updateBuildSiteApp',
        params
    )
}
/**
 * 获取随机id
 */
export function isBuildSiteBelongUser(id){
    return get(
        `/case/build/isBuildSiteBelongUser/${id}`,
    )
}
/**
 * 创建工地
 */
export function createBuildSiteApp(params){
    return post(
        '/case/build/createBuildSiteApp',
        params
    )
}
/**
 * 删除工地
 */
export function deleteBuildSiteApp(id){
    return del(
       `/case/build/deleteBuildSiteApp/${id}`,
    )
}
/**
 * 
 */
export function deleteCardCaseApp(id){
    return del(
       `/case/deleteCardCaseApp/${id}`,
    )
}
/**
 * 获取工地户型
 */
export function findBuildSiteType(){
    return get(
        '/case/build/findBuildSiteType'
    )
}

/**
 * 获取类型
 */
export function findCaseCategory(){
    return get(
        '/case/findCaseCategory'
    )
}
/**
 * 获取随机id
 */
export function isCaseBelongUser(id){
    return get(
       `/case/isCaseBelongUser/${id}`,
    )
}

/**
 * 案例浏览数
 */
export function addNum(id,type){
    return put(
        `/case/build/addNum/${id}/${type}`,
    )
}
/**
 * 创建咨询
 */
export function createCaseConsult(params){
    return post(
        '/article/createConsult',
        params
    )
}
/**
 * 获取二维码地址
 */
export function getBuildSiteDetailsUrl(buildId){
    return get(
        `/case/build/getBuildSiteDetailsUrl/2/${buildId}`
    )
}

